import React, { useEffect } from "react";
import { navigate, useStaticQuery, graphql } from "gatsby";
import { usePrismicPreview } from "gatsby-source-prismic";

export const PreviewPage = ({ location }) => {
  const allPrismicPages = useStaticQuery(graphql`
    {
      allPrismicProject {
        edges {
          node {
            uid
          }
        }
      }
    }
  `);

  const projectUIDS = allPrismicPages.allPrismicProject.edges.map(
    node => node.node.uid
  );

  const pathResolver = () => doc => {
    const previewedUID = doc.uid;
    if (projectUIDS.includes(previewedUID)) {
      return `/projects/${previewedUID}`;
    } else {
      return `/unpublishedPreview`;
    }
  };

  const { previewData, path } = usePrismicPreview(location, {
    repositoryName: `patrickeakinyoung`,
    pathResolver,
  });

  useEffect(() => {
    if (previewData && path) {
      window.__PRISMIC_PREVIEW_DATA__ = previewData;
      navigate(path);
    }
  }, [path, previewData]);

  return <div>Loading preview...</div>;
};

export default PreviewPage;
